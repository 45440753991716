<template>
    <div class="flex justify-center items-center h-screen flex-col text-12 font-bold">
        <div>404</div>
        <div>File not found</div>
    </div>
</template>

<script>
    export default {};
</script>

<style lang="scss" scoped>
</style>